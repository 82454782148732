import React from 'react'
import { Button } from './Button'
import './HeroSection.css'
import '../App.css'

function openVideo() {
    const videoUrl = 'https://youtu.be/dQw4w9WgXcQ?si=jEsWTt-ABTvrNdwk';
    window.open(videoUrl, '_blank'); // Opens the video URL in a new tab or window
  }

function HeroSection() {
    return (
        <div className='hero-container'>
            {/* <video src='/videos/video-2.mp4' autoPlay loop muted/> */}
            <img src="../images/dark-house2.jpg" />
            <h1>Dark House</h1>
            <p>Welcome to the gauntlet</p>
            <div className='hero-btns'>
                <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
                    Click
                    </Button>
                <Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large' onClick={openVideo}>
                    Hype 
                    <i className='far fa-play-circle'/>
                </Button>
            </div>
        </div>
    )
}

export default HeroSection
